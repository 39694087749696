
import { Component, Vue } from "vue-property-decorator";
import { MedicalOrderService, RightsService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";
import router from "src/router";

@Component
export default class GoodsDetail extends Vue {
  /**
   * 数据模型
   * @private
   * @returns any
   */
  private dataModel: any = {
    isNeedAppointment: true,
    marketPrice: null,
    price: null,
    productId: null,
    productName: "",
    productPict: "",
    productTypeStr: "",
    supplierId: null,
    supplierLogo: "",
    supplierName: "",
  };

  /**
   * 卡片信息
   * @private
   * @returns any
   */
  private cardInfo: any = {
    orderId: 0,
    rightsMemberName: "",
    memberId: 0,
    memberName: "",
    phone: "",
    cardNo: "",
    rightsId: 0,
    rightsName: "",
    backgroundColor: "",
    rightsDesc: "",
    activedLimitTime: "",
    activedLimitTimeStr: "",
    activedEffTime: "",
    activedEffTimeStr: "",
  };

  /**
   * 商品详情id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 权益id
   * @private
   * @returns string
   */
  private get rightsId(): string {
    return (this.$route.query && (this.$route.query.rightsId as string)) || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getPreMedicalOrderInfo(this.id);
    this.$nextTick(() => {
      if (this.rightsId) {
        this.getCardByMemberRightId();
      }
    });
  }

  /**
   * 获取订单列表
   * @private
   * @returns void
   */
  private async getCardByMemberRightId(): Promise<void> {
    try {
      let { content: result } = await RightsService.instance.clientGetCardByMemberRightId(this.rightsId);
      if (result.data) {
        this.cardInfo = result.data;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let appid = localStorage.getItem("appid");
    if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
      location.assign(`/${appid}${to.fullPath}`);
    } else {
      next();
    }
  }

  /**
   * 获取产品下单前数据
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getPreMedicalOrderInfo(id: string): Promise<void> {
    try {
      let { content: result } = await MedicalOrderService.instance.getPreMedicalOrderInfo(id);

      if (result.data) {
        this.dataModel = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 提交订单
   * @public
   * @param {params}
   */
  public async onSubmit(): Promise<void> {
    try {
      let data = {
        productId: this.id,
        rightsId: this.rightsId,
      };
      let { content: result } = await MedicalOrderService.instance.createMedicalOrder(data);

      if (result.data) {
        if (result.data.isNeedPay) {
          // 需要支付
          // Pay.onBridgeReady(result.data,
          //     ()=> this.onSucessDetail(result.data.orderId),
          //     ()=> this.onActionOrderDetail(result.data.orderId)
          // )
          this.$router.push({ name: "pay", query: { orderNo: result.data.orderNo } });
        } else {
          this.$router.push({
            name: "medical-order-sucess",
            query: { id: result.data.orderId, rightsId: this.rightsId, productCategory: result.data.productCategory },
          });
        }
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 跳转成功页面
   * @private
   * @returns void
   */
  private onSucessDetail(id: string): void {
    this.$router.push({ name: "medical-order-sucess", query: { id: id } });
  }

  /**
   * 跳转订单明细
   * @private
   * @returns void
   */
  private onActionOrderDetail(id: string): void {
    this.$router.push({ name: "medical-order-detail", query: { id: id } });
  }
}
