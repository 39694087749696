
import { Component, Vue } from "vue-property-decorator";
import { WechatService, MedicalOrderService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";
import { log } from "console";

@Component
export default class GoodsDetail extends Vue {
  // 产品类别映射
  private productCategoryMap: any = {
    greenway: 4, // 绿通
  };

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.isIosF();
    this.getMedicalOrderDetail();
  }

  // ios环境刷新页面
  protected isIosF() {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      if (window.location.href.indexOf("#reloaded") == -1) {
        window.location.replace(window.location.href + "#reloaded");
        window.location.reload();
      }
    }
  }

  /**
   * 服务说明
   * @private
   * @returns boolean
   */
  private interpretOverlay: boolean = false;

  /**
   * 我知道了
   * @private
   * @returns void
   */
  private async onKnow() {
    this.interpretOverlay = false;
  }

  /**
   * 线上去使用
   * @protected
   * @returns void
   */
  private toUse(item) {
    // 无跳转 NOJUMP = 0
    //  小程序 APPLET = 1
    //  URL URL = 2
    if (item.productsLinkType == 0) {
      // 无跳转
      this.interpretOverlay = true;
    } else {
      // H5
      this.$router.push({ name: "medical-buy" }); // 链接保存
      localStorage.setItem("productsLink", item.productsLink);
    }
  }

  /**
   * 数据模型
   * @private
   * @returns any
   */
  private dataModel: any = {
    appletAppId: null,
    actualAmount: null,
    appointEmployerMemberId: null,
    appointMemberId: null,
    appointMemberName: null,
    appointOrgId: null,
    appointOrgName: null,
    appointTime: null,
    appointTimePeriod: null,
    cancelTime: null,
    completedTime: null,
    createdTime: null,
    id: null,
    isNeedAppointment: true,
    isOvertimeUnPay: null,
    marketPrice: null,
    orderNo: "",
    payDeadlineTime: null,
    payedTime: null,
    price: null,
    productId: null,
    productName: "",
    productPict: "",
    productType: 2,
    productTypeStr: "医疗服务",
    refundedTime: null,
    refundingTime: null,
    sourceKind: 2,
    sourceKindStr: "零售购买",
    status: 2,
    statusStr: "",
    supplierId: null,
    supplierLogo: "",
    supplierName: "",
    isFromRights: false,
    rightsId: null,
    rightsName: "",
    healthServiceType: null, // 线上医疗服务
    productsLink: "",
    m78UserToken: "",
    rightsOrderNo: null,
    memberId: null,
  };

  /**
   * 去使用
   * @protected
   * @returns void
   */
  //  productsLink
  protected onApply(dataModel) {
    if (this.dataModel.productsLinkType == 2) {
      // 链接保存
      this.$router.push({ name: "medical-buy" });
      localStorage.setItem("productsLink", this.dataModel.productsLink);
    }
  }

  /**
   * 获取订单详情
   * @protected
   * @returns void
   * @params blo -  true: 点击调取，false: 第一次初始化；
   */
  protected async getMedicalOrderDetail(blo: boolean = false) {
    try {
      let { content: result } = await MedicalOrderService.instance.getMedicalOrderDetail(this.id, false);

      if (result.data) {
        this.dataModel = result.data;
        let type = null;
        if (this.dataModel.productCategory == 2) {
          type = 0;
        } else {
          type = 1;
        }

        let data = {
          token: this.dataModel.m78UserToken,
          businessId: this.dataModel.patientPackageId,
          memberId: this.dataModel.memberId,
          rightsOrderNo: this.dataModel.rightsOrderNo,
          rightsId: this.dataModel.rightsId,
          businessNo: this.dataModel.orderNo,
          type: type,
        };

        let cancelParams = {
          appid: this.dataModel.appletAppId,
          eleId: "apply", // 元素id
          url: this.dataModel.productsLink,
          content: this.reportContent, // 自定义的html内容
          extradata: JSON.stringify(data),
        };
        if (result.data.status == 2 && !blo) {
          this.wx_launch(cancelParams);
        } else if (result.data.status != 2 && blo) {
          Toast("状态已变更！");
          setTimeout(() => {
            window.location.reload();
            // this.$router.go(0)
          }, 500);
        }
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private async wx_launch(info): Promise<void> {
    if (!(await tools.isWechat())) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp id="aaaaaa" style="width:100%; height:100%;display:block;overflow: hidden;" appid="${info.appid}" path="${info.url}" env-version="trial" extra-data='${info.extradata}' >${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  /**
   * 订单id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 权益id
   * @private
   * @returns string
   */
  private get rightsId(): string {
    return (this.$route.query && (this.$route.query.rightsId as string)) || "";
  }

  /**
   * 产品类别
   * @private
   * @returns string
   */
  private get productCategory(): string {
    return (this.$route.query && (this.$route.query.productCategory as string)) || "";
  }

  private reportContent: string = "";

  /**
   * dom加载完
   * @protected
   * @returns void
   */
  protected mounted() {
    this.$nextTick(() => {
      this.init();
      this.reportContent = `
            <button class="test-btn"></button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 520px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            `;
    });
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (await tools.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }

  /**
   * 跳转健康首页
   * @private
   * @returns void
   */
  private onHealthHome(): void {
    this.$router.push({ name: "health-product-home" });
  }

  /**
   * 跳转首页
   * @private
   * @returns void
   */
  private onHome(): void {
    this.$router.push({ name: "home" });
  }

  /**
   * 跳转订单详情
   * @private
   * @returns void
   */
  private onOrder(): void {
    this.$router.push({ name: "medical-order-detail", query: { id: this.id } });
  }

  /**
   * 预约服务订单
   * @protected
   * @returns void
   */
  protected onAppointment() {
    // 绿通
    if (this.productCategory == this.productCategoryMap.greenway) {
      this.$router.push({ name: "greenway-reservation", query: { id: this.id } });
    } else {
      this.$router.push({ name: "medical-order-appointment", query: { id: this.id } });
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }
}
