
import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, PackageService, OrderService, FamilyService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { EnumUtils, tools } from "src/utils";

@Component
export default class OfteUser extends Vue {
  /**
   * 订单id
   * @private
   * @returns string
   */
  private get orderId(): string {
    if (this.$route.query && this.$route.query.id) {
      return this.$route.query.id + "";
    }

    return null;
  }

  /**
   * 跳转订单详情
   * @private
   * @returns number
   */
  private onOrderDetail(): void {
    this.$router.push({ name: "medical-order-detail", query: { id: this.orderId } });
  }
}
