
import { Component, Vue } from "vue-property-decorator";
import { HealthProductService, OrganizationService, WechatService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";

@Component
export default class GoodsDetail extends Vue {
  /**
   * 数据模型
   * @private
   * @returns any
   */
  protected dataModel: any = {
    bannerPicts: "",
    bannerPictsUrls: [],
    id: null,
    infoPicts: "",
    infoPictsUrls: [],
    introduce: "",
    isNeedAppointment: false,
    mainPict: "",
    mainPictUrl: "",
    marketPrice: null,
    name: "",
    price: null,
    remark: "",
    supplierId: null,
    supplierName: "",
    healthServiceType: 0,
  };

  /**
   * 选中tab值
   * @private
   * @returns string
   */
  private active: string = "";

  /**
   * 商品详情id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 权益id
   * @private
   * @returns string
   */
  private get rightsId(): string {
    return (this.$route.query && (this.$route.query.rightsId as string)) || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 当前位置
   * @private
   * @returns any
   */
  private get location(): any {
    return this.$store.getters.location;
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getHealthProductDetail(this.id);
    this.getDataList();
  }

  /**
   * 点击购买
   * @protected
   * @returns void
   */
  protected onBuy() {
    this.$router.push({ name: "medical-order-submit", query: { id: this.id, rightsId: this.rightsId } });
  }

  /**
   * 获取健康产品详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getHealthProductDetail(id: string): Promise<void> {
    try {
      let { content: result } = await HealthProductService.instance.getHealthProductDetail(id);
      if (result.data) {
        this.dataModel = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 是否在加载中
   * @private
   * @returns boolean
   */
  private isLoading: boolean = false;

  /**
   *
   * @private
   * @returns string
   */
  private dataParams: any = {
    id: this.id,
    longitude: (this.location && this.location.lng) || null,
    latitude: (this.location && this.location.lat) || null,
    cityName: (this.location && this.location.city) || null,
  };

  /**
   * 数据列表
   * @private
   * @returns Array<any>
   */
  private DataList: Array<any> = [];

  /**
   * 获取数据
   * @private
   * @returns void
   */
  private async getDataList(params: any = {}): Promise<void> {
    this.isLoading = true;
    try {
      let { content: result } = await OrganizationService.instance.getNearestHospitalList(this.dataParams);
      if (result.data) {
        this.DataList = result.data.hospitals;
        let currentUrl = location.href;
      }
    } catch (err) {
      Toast(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * 跳转地图
   * @private
   * @returns void
   */
  private async openMap(DataList: any): Promise<any> {
    if (DataList) {
      let lat = DataList.latitude;
      let lng = DataList.longitude;
      let address = DataList.address;
      let orgName = DataList.name;

      if (await tools.isWechat()) {
        let currentUrl = location.href;
        let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
        if (result.data) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.data.appId, // 必填，公众号的唯一标识
            timestamp: result.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
            signature: result.data.signature, // 必填，签名
            jsApiList: ["openLocation"], // 必填，需要使用的JS接口列表
          });

          wx.ready(function () {
            wx.openLocation({
              latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
              longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
              scale: 14, // 缩放比例
              name: orgName,
              address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
              infoUrl: "",
            });
          });
        }
      } else {
        // 腾讯地图
        // let href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
        // 高德
        let href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
        window.open(href);
      }
    }
  }
}
